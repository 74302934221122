#home #header.sticky,
#offshore #header.sticky {
	background-color: #fff;
	transition: 1.3s all;
}

#home .title-left {
	margin-bottom: 40px;
}

#home .title-left h3 {
	font-size: 48px;
	font-weight: 900;
	line-height: 56px;
	color: #0063aa;
	position: relative;
	margin-bottom: 29px;
	text-transform: uppercase;
}

#home .title-left h3::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: -13px;
	width: 48px;
	height: 4px;
	background-color: #0063aa;
	z-index: 1;
}

/* #home .r-process .col-right ul li:hover::before {
	opacity: 1;
} */

#home .title-center {
	margin-bottom: 40px;
	text-align: center;
}

#home .r-office-tcom .col-left {
	flex: 0 0 27%;
	display: flex;
	max-width: 27%;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

#home .title-center h3 {
	font-size: 48px;
	font-weight: 900;
	line-height: 56px;
	color: #0063aa;
	position: relative;
	margin-bottom: 29px;
	text-transform: uppercase;
}

#home .title-center h3::after {
	content: '';
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -13px;
	width: 48px;
	height: 4px;
	background-color: #0063aa;
	z-index: 1;
}

#home .title-center .desc {
	font-size: 20px;
	color: #686868;
}

#home .r-big-banner {
	/*padding: 15% 0%;*/
	background: url('/assets/images/home/big-banner-bg-1.png') no-repeat center;
	background-size: cover;
}

#home .r-big-banner .content {
	width: 455px;
	max-width: 100%;
}

#home .r-big-banner .content h1 {
	font-weight: 700;
	font-size: 56px;
	line-height: 68px;
	color: #0063aa;
	margin-bottom: 25px;
}

#home .r-big-banner .content p {
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
	color: #434343;
	margin-bottom: 50px;
}

#home .r-big-banner .content .btn-readmore {
	display: inline-flex;
	color: #fff;
	font-weight: 700;
	font-size: 32px;
	line-height: 24px;
	align-items: center;
	padding: 24.5px 45px;
	background: #0063aa;
	border-radius: 8px;
}

#home .r-big-banner .content .btn-readmore:hover {
	background-color: #06436f;
}

#home .r-big-banner .content .btn-readmore svg {
	margin-left: 40px;
}

#home .r-featured-news {
	background-color: #0063aa;
	color: #fff;
}

#home .r-featured-news h3.title {
	font-weight: 900;
	font-size: 40px;
	line-height: 47px;
	margin-bottom: 0;
}

#home .r-featured-news .news-carousel {
	padding-left: 78px;
}

#home .r-featured-news .news-carousel .slick-list .slick-track {
	display: flex;
	align-items: center;
}

#home .r-featured-news .news-carousel::before {
	content: '';
	height: 88px;
	width: 2px;
	background-color: #fff;
	position: absolute;
	left: 26px;
	top: 50%;
	transform: translateY(-50%);
}

#home .r-featured-news .news-carousel::after {
	content: '';
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-radius: 50%;
	display: block;
	position: absolute;
	left: 54px;
	top: 50%;
	transform: translateY(-50%);
}

#home .r-featured-news .news-carousel .inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#home .r-featured-news .news-carousel .inner p {
	font-weight: 700;
	font-size: 24px;
	line-height: 40px;
	margin-bottom: 0;
	width: calc(100% - 119px);
}

#home .r-featured-news a.btnNextNews {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

/* #home .r-process .col-right ul li:hover .content p {
	padding-right: 52px;
	font-size: 87px;
	transition: 0.4s;
} */

#home .r-featured-news .news-carousel .slick-prev {
	background: none;
	position: absolute;
	right: 100%;
	border: none;
	top: 50%;
	left: -300px;
	transform: translateY(-50%);
	z-index: 1;
}

#home .r-featured-news .news-carousel .slick-prev .owl-next {
	margin-left: 24px;
}

#home .r-featured-news .news-carousel .slick-next {
	background: none;
	position: absolute;
	right: 100%;
	border: none;
	top: 50%;
	left: -190px;
	transform: translateY(-50%);
	z-index: 1;
}

#home .r-service {
	padding: 59px 0 96px;
}

#home .r-service .top .link {
	display: block;
	text-align: right;
	padding-top: 72px;
}

#home .r-service .service-list .row {
	margin-left: -4.5px;
	margin-right: -4.5px;
	flex-wrap: wrap;
	width: 100%;
	column-gap: 1.3%;
}

#home .r-service .service-list .col-12 {
	padding-left: 4.5px;
	padding-right: 4.5px;
	margin-bottom: 9px;
}

#home .r-service .service-list .box {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 9px;
	padding: 19px 18px 13px 24px;
	transition: 0.2s all;
	width: 32%;
	margin-bottom: 1.3%;
}

#home .r-service .service-list .box:hover {
	border-color: #0063aa;
}

#home .r-service .service-list .box:hover .more {
	opacity: 1;
	visibility: visible;
}

#home .r-service .service-list .box .icon {
	margin-bottom: 15px;
}

#home .r-service .service-list .box h3 {
	font-weight: 700;
	font-size: 19px;
	line-height: 22px;
	color: #121212;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	position: relative;
}

#home .r-service .service-list .box p {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	width: 100%;
	color: #686868;
}

#home .r-service .service-list .box ul li {
	line-height: 20px;
	color: #686868;
	position: relative;
}

#home .r-service .service-list .box ul li::before {
	content: '';
	width: 2px;
	height: 2px;
	background-color: #686868;
	border-radius: 50%;
	position: absolute;
	left: -10px;
	top: 8px;
}

#home .r-service .service-list .box .more {
	letter-spacing: 0.08em;
	font-size: 16px;
	font-weight: 900;
	color: #0063aa;
	display: flex;
	align-items: center;
	margin-top: auto;
	opacity: 0;
	visibility: hidden;
}

#home .r-service .service-list .box .more svg {
	margin-left: 15px;
	margin-bottom: 3px;
}

#home .r-reason {
	color: #fff;
	padding: 122px 0 135px;
	background: url('/assets/images//home/reason-bg.png') no-repeat center;
	background-size: cover;
}

#home .r-reason .label {
	position: absolute;
	right: calc(100% + 84px);
	top: calc(50% + 44px);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.icon-mb {
	display: none;
}

#home .r-reason .title {
	font-weight: 900;
	font-size: 64px;
	line-height: 75px;
	text-align: center;
	margin-bottom: 123px;
}

#home .r-reason .box {
	display: flex;
	align-items: center;
}

#home .r-reason .box .group-reason {
	margin-right: 60px;
}

#home .r-reason .box .group-reason img {
	max-width: 70%;
}

#home .r-reason .box .group-reason img {
	/* max-width: 100%; */
	margin-top: -4px;
}

#home .r-reason .box ul li {
	display: flex;
	align-items: center;
}

#home .r-reason .box ul li+li {
	margin-top: 62px;
}

#home .r-reason .box ul li .icon {
	margin-right: 32px;
	min-width: 95px;
}

#home .r-reason .box ul li p {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 900;
	font-size: 38px;
	line-height: 58px;
	margin-bottom: 0;
}

#home .r-coporate {
	margin-top: 69px;
}

#home .r-coporate .top {
	text-align: center;
	position: relative;
	margin-bottom: 63px;
}

#home .r-coporate .top h2 {
	font-weight: 900;
	font-size: 56px;
	line-height: 86px;
	color: #0363ab;
	margin-bottom: 17px;
}

#home .r-coporate .top p {
	font-size: 18px;
	color: #292929;
}

#home .r-coporate .top .link {
	position: absolute;
	bottom: -2px;
	right: 0;
	z-index: 1;
}

#home .r-coporate .row {
	margin-left: -15px;
	margin-right: -15px;
}

#home .r-coporate .row>div {
	padding-left: 15px;
	padding-right: 15px;
}

#home .r-coporate .row .box {
	position: relative;
}

#home .r-coporate .row .box>img {
	width: 100%;
}

#home .r-coporate .row .box .content {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	padding: 33px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#home .r-office-tcom .col-right {
	flex: 0 0 73%;
	max-width: 73%;
}

#home .r-coporate .row .box .content .icon {
	margin-top: calc(50% - 54px);
	transform: translateY(-50%);
}

#home .r-coporate .row .box .content .inner {
	background-color: #fff;
	padding: 23px 20px 33px;
	border-radius: 16px;
	max-height: 239px;
	height: 100%;
}

#home .r-coporate .row .box .content .inner h1 {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 900;
	font-size: 48px;
	line-height: 84px;
	color: #0063aa;
	margin-bottom: 16px;
}

#home .r-coporate .row .box .content .inner p {
	line-height: 32px;
	font-size: 18px;
	color: #292929;
	margin-bottom: 40px;
	font-weight: 500;
}

#home .r-coporate .row .box .content .inner .customFz {
	padding-bottom: 6px;
	font-size: 48px;
	margin-top: 31px;
}

#home .r-coporate .row .box .content .customPd {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
}

#home .r-process {
	margin-top: 103px;
	padding-bottom: 91px;
	background: #0063aa;
}

#home .r-process .col-left {
	padding-top: 91px;
	position: relative;
	height: calc(100% + 91px);
}

#home .r-process .col-left h3 {
	font-weight: 700;
	font-size: 40px;
	line-height: 47px;
	text-transform: uppercase;
	color: #ffffff;
	position: relative;
}

#home .r-process .col-left h3::after {
	content: '';
	width: 48px;
	height: 4px;
	background: #ffffff;
	position: absolute;
	bottom: -19px;
	left: 0;
}

#home .r-process .col-left p {
	padding-top: 25px;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: #ffffff;
}

#home .r-process .col-left .subtract-2 {
	position: absolute;
	top: 0;
	left: -334px;
}

#home .r-process .col-left .subtract {
	position: absolute;
	bottom: 0;
	left: -403px;
}

#home .r-process .col-right {
	padding-left: 45px;
	position: relative;
	padding-bottom: 1px;
	margin-bottom: -28px;
}

#home .r-process .col-right ul {
	padding-top: 70px;
}

#home .r-process .col-right ul::before {
	content: '';
	width: 1px;
	height: calc(100% + 90px);
	position: absolute;
	left: 76px;
	z-index: 1;
	top: 0;
	background: #fff;
}

#home .r-process .col-right ul li {
	position: relative;
	color: #ffffff;
	margin-bottom: calc(65px + 1rem);
	display: flex;
	transition: 0.5s;
	cursor: pointer;
}

#home .r-process .col-right ul li:last-of-type {
	margin-bottom: 0;
}

#home .r-process .col-right ul li::before {
	content: '';
	background: rgba(255, 255, 255, 0.1);
	border-radius: 1400px 1991.68px 1991.68px 1400px;
	opacity: 0;
	width: 601px;
	height: 125px;
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
}

/* #home .r-process .col-right ul li:hover .content .text strong {
	transition: 0.3s;
	font-size: 32px;
} */

#home .r-process .col-right ul li::after {
	content: '';
	width: 3px;
	height: 125px;
	background-color: #fff;
	position: absolute;
	left: -63px;
	opacity: 0;
	transition: 0.5s;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 5px;
}

/* #home .r-process .col-right ul li:hover::after {
	opacity: 1;
} */

#home .r-process .col-right ul li .icon {
	cursor: pointer;
	position: relative;
	z-index: 2;
	transition: 0.5s;
	display: flex;
}

#home .r-process .col-right ul li .content {
	padding-left: 30px;
	display: flex;
	transition: 0.5s;
	margin-top: 1px;
	align-items: center;
}

#home .r-process .col-right ul li .content p {
	font-weight: 700;
	font-size: 48px;
	line-height: 56px;
	padding-right: 39px;
	position: relative;
	transition: 0.5s;
	margin-top: 4px;
	margin-bottom: 0;
}

#home .r-process .col-right ul li .content p::before {
	content: '';
	width: 3px;
	position: absolute;
	right: 11px;
	top: 50%;
	transform: translateY(-50%);
	height: 65px;
	border-radius: 7px;
	background: #fff;
}

#home .r-process .col-right ul li .content .text strong {
	font-weight: 700;
	font-size: 24px;
	line-height: 34px;
	transition: 0.5s;
}

#home .r-process .col-right ul li .content .text span {
	position: absolute;
	bottom: 1px;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	transition: 0.5s;
}

#home .r-technology {
	margin-top: 135px;
	padding: 0;
}

#home .r-technology .title-left {
	margin-bottom: 49px;
	position: relative;
}

#home .r-technology .title-left h3 {
	margin-bottom: 37px;
}

#home .r-technology .title-left .desc {
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
}

#home .r-technology .title-left .link {
	position: absolute;
	bottom: -2px;
	right: 0;
	z-index: 1;
}

#home .r-technology .col-12 {
	margin-bottom: 19px;
}

#home .r-technology .box {
	padding: 18px 20px 33px;
	background: #F8FCFF;
	border: 1px solid #0063AA;
	border-radius: 8px;
	height: 100%;
	position: relative;
}

#home .r-technology .box::after {
	position: absolute;
	bottom: -4px;
	right: 19px;
	font-weight: 900;
	font-size: 107px;
	letter-spacing: -0.02em;
	color: rgba(196, 196, 196, 0.15);
	line-height: 1;
}

/* 
#home .r-technology .box.backend::after {
	content: 'Backend';
}

#home .r-technology .box.frontend::after {
	content: 'Frontend';
}

#home .r-technology .box.mobility::after {
	content: 'Mobility';
	bottom: 20px;
}

#home .r-technology .box.blockchain::after {
	content: 'Blockchain';
}

#home .r-technology .box.database::after {
	content: 'Data Base';
}

#home .r-technology .box.ai::after {
	content: 'A.I';
} */

#home .r-technology .box.ai h3 {
	margin-bottom: 5px;
}

#home .r-technology .box.ai ul li {
	margin-bottom: -9px;
}

#home .r-technology .box h3 {
	font-size: 32px;
	font-weight: 700;
	color: #0063aa;
	margin-bottom: 17px;
	letter-spacing: -0.02em;
}

#home .r-technology .box ul {
	display: flex;
	flex-wrap: wrap;
}

#home .r-technology .box ul li {
	flex: 0 0 20%;
	max-width: 20%;
	text-align: center;
	margin-bottom: 5px;
}

#home .r-technology .box ul li .icon {
	height: 61px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 7px;
}

#home .r-technology .box ul li .name {
	font-size: 16px;
	font-weight: 700;
	color: #000;
	line-height: 19px;
}

#home .r-featured-project {
	margin-top: 88px;
	background-color: #0063aa;
	padding: 138px 0 112px;
}

#home .r-featured-project .title-left {
	position: relative;
	margin-bottom: 83px;
}

#home .r-featured-project .title-left h3 {
	color: #fff;
}

#home .r-featured-project .title-left h3::after {
	background-color: #fff;
}

#home .r-featured-project .title-left .desc {
	color: #fff;
}

#home .r-featured-project .title-left .link {
	position: absolute;
	bottom: -14px;
	right: 0;
	z-index: 1;
}

#home .r-featured-project .featured-project-carousel::before {
	content: '';
	width: 10000%;
	right: 100%;
	height: 100%;
	position: absolute;
	background-color: #0063aa;
	z-index: 10;
}

#home .featured-project-carousel {
	position: relative;
}

#home .featured-project-carousel:hover .slick-arrow.slick-next {
	opacity: 1 !important;
}

#home .r-featured-project .customSlickSlider .slick-arrow.slick-prev {
	display: none !important;
}

#home .r-featured-project .customSlickSlider .slick-arrow.slick-next {
	background: none;
	border-radius: 50%;
	border: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: calc(100% + 200px);
	opacity: 0;
	transition: 0.3s all;
}

#home .r-featured-project .customSlickSlider .slick-list {
	overflow: visible;
	margin-left: -15px;
	margin-right: -15px;
}

#home .r-featured-project .slick-slide {
	padding: 0 15px;
}

#home .r-featured-project .item .box {
	position: relative;
}

#home .r-featured-project .item .box::before {
	content: '';
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 100%;
	height: 297px;
	background: linear-gradient(0deg, #353535 0%, rgba(53, 53, 53, 0) 100%);
}

#home .r-featured-project .item .box .image img {
	width: 100%;
}

#home .r-featured-project .item .box .content {
	padding: 21px 42px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

#home .r-featured-project .item .box .content h3 {
	font-weight: 700;
	font-size: 40px;
	line-height: 47px;
	color: #fff;
	margin-bottom: 25px;
}

#home .r-featured-project .item .box .content p {
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: #fff;
	padding-right: 40px;
	/* position: absolute;
left: 42px;
top: -183px; */
}

#home .r-archivement {
	padding: 97px 0 133px;
	background: url('/assets/images//home/archivement-bg.png') no-repeat center;
}

#home .r-archivement .title-center {
	margin-bottom: 56px;
}

#home .r-archivement .title-center h3::after {
	bottom: -15px;
	left: calc(50%);
	transform: translateX(-50%);
}

#home .r-archivement .title-center p {
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #0063aa;
	padding-top: 8px;
}

#home .r-archivement .row {
	margin-left: -21px;
	margin-right: -21px;
}

#home .r-archivement .row>div {
	padding-left: 21px;
	padding-right: 21px;
}

#home .r-archivement .box {
	padding: 28px 33px 23px;
	box-shadow: inset 0px 0px 8.29464px rgba(0, 0, 0, 0.1);
	border-radius: 6.63571px;
	height: 100%;
}

#home .r-archivement .box.box-1 {
	background-color: #0063aa;
}

#home .r-archivement .box.box-2 {
	background-color: #fde002;
}

#home .r-archivement .box.box-2 h2,
#home .r-archivement .box.box-2 p {
	color: #0063aa;
}

#home .r-archivement .box.box-3 {
	background-color: #eb2725;
}

#home .r-archivement .box.box-4 {
	background-color: #00be90;
}

#home .r-archivement .box .icon {
	height: 81px;
	margin-bottom: 26px;
	display: flex;
	align-items: flex-end;
}

#home .r-archivement .box h2 {
	font-weight: 900;
	font-size: 42px;
	line-height: 49px;
	color: #fff;
	margin-bottom: 5px;
}

#home .r-archivement .box p {
	font-weight: 500;
	font-size: 24px;
	line-height: 33px;
	color: #fff;
	margin-bottom: 0;
}

#home .r-recruitment {
	padding: 110px 0 153px;
	background-color: #f0f9ff;
	background: url('/assets/images//home/recruitment-bg.png') no-repeat center;
	background-size: cover;
}

#home .r-recruitment .image img {
	position: absolute;
	right: 7px;
	z-index: 2;
}

#home .r-recruitment .title-left {
	position: relative;
	margin-bottom: 100px;
}

#home .r-recruitment .title-left h3 {
	font-weight: 900;
	font-size: 68px;
	line-height: 80px;
	margin-bottom: 20px;
}

#home .r-recruitment .title-left h3::after {
	display: none;
}

#home .r-recruitment .title-left .desc {
	color: #0063aa;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
}

#home .r-recruitment .title-left .link {
	position: absolute;
	bottom: -30px;
	right: 0;
	z-index: 1;
}

#home .r-recruitment .job-list .box {
	display: flex;
	border: 2px solid #0063aa;
	border-radius: 8px;
}

#home .r-recruitment .job-list .box .group-content {
	display: flex;
	align-items: center;
}

#home .r-recruitment .job-list .box+.box {
	margin-top: 25px;
}

#home .r-recruitment .job-list .box .check {
	background-color: #0063aa;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 62px 29px;
	margin-right: 26px;
}

#home .r-recruitment .job-list .box .title {
	min-width: 200px;
	margin-right: 20px;
}

#home .r-recruitment .job-list .box .title h2 {
	font-weight: 900;
	font-size: 46px;
	line-height: 80px;
	color: #0063aa;
	margin-bottom: 0;
}

#home .r-recruitment .job-list .box .content h3 {
	font-weight: 700;
	font-size: 32px;
	line-height: 47px;
	color: #5a5a5a;
	margin-bottom: 11px;
}

#home .r-recruitment .job-list .box .content ul li {
	font-weight: 900;
	font-size: 18px;
	line-height: 21px;
	color: #5a5a5a;
	display: flex;
}

#home .r-recruitment .job-list .box .content ul li+li {
	margin-top: 13px;
}

#home .r-recruitment .job-list .box .content ul li .name {
	min-width: 100px;
	margin-right: 18px;
}

#home .r-office-tcom {
	margin-top: 96px;
	margin-bottom: 156px;
}

#home .r-office-tcom .circle {
	position: absolute;
	z-index: 0;
}

#home .r-office-tcom .circle.circle-1 {
	border-radius: 50%;
	width: 128px;
	height: 128px;
	background-color: #0063aa;
	top: -74px;
	right: -88px;
}

#home .r-office-tcom .circle.circle-2 {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	background-color: #eb2725;
	bottom: 160px;
	right: 102px;
}

#home .r-office-tcom .circle.circle-3 {
	border-radius: 50%;
	width: 44px;
	height: 44px;
	background-color: #fde002;
	bottom: 41px;
	left: 120px;
}

#home .r-office-tcom .col-left {
	z-index: 1;
}

#home .r-office-tcom .col-left .inner {
	padding-left: 112px;
	padding-top: 141px;
}

#home .r-office-tcom .col-left p {
	font-size: 24px;
	color: #0063aa;
	margin-bottom: 1px;
}

#home .r-office-tcom .col-left h3 {
	font-weight: 700;
	font-size: 48px;
	line-height: 56px;
	color: #0063aa;
	margin-bottom: 46px;
	position: relative;
}

#home .r-office-tcom .col-left h3::before {
	content: '';
	width: 123px;
	height: 5px;
	background-color: #0063aa;
	border-radius: 43px;
	position: absolute;
	left: 0;
	bottom: -26px;
}

#home .r-office-tcom .col-left .btn-start {
	border: 2px solid #0063aa;
	filter: drop-shadow(0px 4px 41px rgba(189, 189, 189, 0.25));
	border-radius: 54px;
	padding: 18px 32px 18px 32px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 32px;
	line-height: 38px;
	color: #0063aa;
	background-color: #fff;
}

#home .r-office-tcom .col-left .btn-start svg {
	margin-left: 20px;
}

#home .r-office-tcom .col-right:hover .tcom-image .slick-arrow {
	opacity: 1 !important;
}

#home .r-office-tcom .col-right .tcom-image {
	margin-right: 352px;
	margin-left: 17px;
	position: relative;
}

#home .r-office-tcom .col-right .tcom-image .slick-dots {
	position: absolute;
	left: 0;
	display: flex !important;
	margin-top: 36px;
}

#home .r-office-tcom .col-right .tcom-image .slick-dots li {
	margin-right: 16px;
}

#home .r-office-tcom .col-right .tcom-image .slick-dots li.slick-active button {
	width: 37px;
	background-color: #0063aa;
	border-radius: 27px;
}

#home .r-office-tcom .col-right .tcom-image .slick-dots button {
	width: 10px;
	height: 10px;
	background-color: #d9d9d9;
	border: none;
	border-radius: 50%;
	overflow: hidden;
	font-size: 0;
	padding: 0;
	transition: 0.4s all;
}

#home .r-office-tcom .col-right .tcom-image .slick-arrow {
	background: transparent;
	border: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	padding: 0;
	opacity: 0;
	transition: 0.4s all;
	z-index: 10;
}

#home .r-office-tcom .col-right .tcom-image .slick-arrow.slick-disabled {
	opacity: 0 !important;
}

#home .r-office-tcom .col-right .tcom-image .slick-arrow.slick-next {
	right: -226px;
}

#home .r-office-tcom .col-right .tcom-image .slick-arrow.slick-prev {
	display: none !important;
	left: -141px;
	-webkit-transform: translateY(-50%) rotate(180deg);
	transform: translateY(-50%) rotate(180deg);
}

#home .r-office-tcom .col-right .tcom-image .slick-list {
	overflow: visible;
}

#home .r-office-tcom .col-right .tcom-image .slick-list::before {
	content: '';
	width: 10000%;
	height: 100%;
	position: absolute;
	background-color: #fff;
	right: 100%;
	z-index: 10;
}

#home .r-office-tcom .col-right .tcom-image .slick-track {
	margin: 0 -20px;
	display: flex;
	align-items: center;
}

#home .r-office-tcom .col-right .tcom-image .slick-active {
	margin-right: -91px;
}

#home .r-office-tcom .col-right .tcom-image .slick-active .item {
	transform: scale(1) !important;
}

#home .r-office-tcom .col-right .tcom-image .slick-slide .item {
	transform: scale(0.823);
}

#home .r-office-tcom .col-right .tcom-image .item {
	border: none;
	padding: 0 20px;
	transform: scale(0.823);
	max-width: 986px;
}

#home .r-office-tcom .col-right .tcom-image .item:focus-visible {
	outline: none;
}

#home .r-office-tcom .col-right .tcom-image .item.slick-active {
	transform: scale(1);
	margin-right: -82px;
}

#home .r-office-tcom .col-right .tcom-image .item img {
	width: 100%;
	border-radius: 15px;
}

#home .r-office-tcom .col-right .tcom-image .item img:focus-visible {
	outline: none;
}

#home .r-office-tcom .col-right .tcom-image .item a {
	display: block;
	position: relative;
}

#home .r-office-tcom .col-right .tcom-image .item a:hover .image img {
	transform: scale(1.1);
}

#home .r-office-tcom .col-right .tcom-image .item a .content {
	position: absolute;
	bottom: 36px;
	left: 25px;
	width: calc(100% - 40px);
}

#home .r-office-tcom .col-right .tcom-image .item a .content h3 {
	color: #fff;
	font-weight: 900;
	font-size: 28px;
	line-height: 36px;
}

#home .r-office-tcom .col-right .tcom-image .image {
	border-radius: 15px;
	overflow: hidden;
}

#home .r-office-tcom .col-right .tcom-image .image img {
	width: 100%;
	transition: 0.4s all;
}

#home .r-news {
	margin-top: 109px;
	padding-bottom: 199px;
	background: url('/assets/images//home/news-bg.png') no-repeat bottom;
	background-size: contain;
}

#home .r-news ul.list {
	padding: 0 7px;
}

#home .r-news a.handleBtnMore {
	font-weight: 700;
	font-size: 19.2px;
	line-height: 23px;
	color: #0063aa;
	margin-left: auto;
	margin-right: auto;
	border: 1.2px solid #0063aa;
	border-radius: 32.4px;
	padding: 11px 20px;
}

#home .r-news a.handleBtnMore span {
	margin-right: 33px;
}

#home .r-news .btnMoreNews {
	text-align: center;
	margin-top: 90px;
	display: none;
}

#home .r-news .title-left {
	position: relative;
	margin-bottom: 58px;
}

#home .r-news .title-left h3 {
	margin-bottom: 28px;
}

#home .r-news .title-left .desc {
	font-weight: 700;
	font-size: 24px;
	line-height: 28px;
	color: #0063aa;
}

#home .r-news .title-left .link {
	position: absolute;
	bottom: -13px;
	right: -6px;
	z-index: 1;
}

#home .r-news .list li {
	display: flex;
	gap: 60px;
	position: relative;
}

#home .r-news .list li+li {
	margin-top: 55px;
}

#home .r-news .list li .image {
	overflow: hidden;
	cursor: pointer;
	width: 445px;
	min-width: 445px;
	max-width: 100%;
}

#home .r-news .list li .image img {
	transition: 0.4s all;
}

#home .r-news .list li .image img:hover {
	transform: scale(1.1);
}

#home .r-news .list li .type {
	display: flex;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	color: #0063aa;
	margin-right: 101px;
	position: relative;
}

#home .r-news .list li .type .date {
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	color: #393939;
	display: flex;
	align-items: center;
	margin-right: 42px;
	padding: 44px 0 31px;
}

#home .r-news .list li .type span {
	border: 1px solid #0063aa;
	margin-top: 32px;
	border-radius: 250px;
	padding: 3px 27px;
	height: 49px;
	text-align: center;
	display: flex;
	justify-content: center;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	text-align: center;
	align-items: center;
	white-space: nowrap;
}

#home .r-news .list li .title {
	font-weight: 700;
	font-size: 18px;
	line-height: 32px;
	color: #393939;
	display: block;
	margin-bottom: 24px;
	cursor: default;
}

#home .r-news .list li .handle {
	font-weight: 700;
	font-size: 18px;
	line-height: 32px;
}

#home .r-news .list li .handle span {
	margin-right: 14px;
	color: #0063aa;
}

#home .r-faq {
	margin-top: -20px;
}

#home .r-faq .title-center {
	margin-bottom: 75px;
}

#home .r-faq .title-center h3 {
	font-weight: 700;
	font-size: 38px;
	line-height: 55px;
	letter-spacing: -0.01em;
	font-family: 'Noto Sans JP';
}

#home .r-faq .title-center h3::after {
	bottom: 1px;
	width: 328px;
}

#home .r-faq #accordion {
	border-top: 1px solid #464646;
}

#home .r-faq .card {
	border: none;
	border-bottom: 1px solid #464646;
	border-radius: 0;
}

#home .r-faq .card .card-header {
	background: transparent;
	border: none;
	padding: 0;
}

#home .r-faq .card button {
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #0063aa;
	border: none;
	background-color: transparent;
	padding: 0;
	width: 100%;
	text-align: left;
	padding: 28px 50px 28px 0;
	background: url('/assets/images//home/icon-minus.png') no-repeat calc(100% - 7px) center;
}

#home .r-faq .card button.collapsed {
	background: url('/assets/images//home/icon-plus.png') no-repeat calc(100% - 7px) center;
}

#home .r-faq .card .card-body {
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;
	color: #464646;
	padding: 0 111px 10px 0;
	margin-top: -4px;
}

#home .r-big-banner .content p {
	font-size: 20px;
	line-height: 0px;
}

#home .r-big-banner .content {
	width: 630px;
	max-width: 100%;
	/* margin-top: 50px; */
	text-align: center;
}

#home .r-big-banner .content h1 {
	font-size: 36px;
	line-height: 50px;
}

#home .r-featured-project .featured-project-carousel::before {
	height: 101%;
}

#home .r-office-tcom .col-left .inner {
	padding-top: 0px;
}

#home .r-contact {
	display: none;
}

/* 
#home .r-technology .box.database::after {
	content: 'DataBase';
} */

#home .r-recruitment .job-list .box .check::after {
	background: #0063aa;
	margin-top: 0px;
	content: '';
	height: 154px;
	left: 88px;
	position: absolute;
	width: 7px;
}

#home .r-recruitment .job-list .box .check {
	display: flex;
	border: 2px solid #0063aa;
	border-radius: 8px;
	margin-left: -2px;
	margin-bottom: -1px;
	margin-top: -1px;
	position: relative;
}

#home .r-coporate .row .box .content .inner {
	padding: 23px 20px 23px;
}

#home .title-left .desc {
	font-size: 18px;
	color: #434343;
}

#home .r-featured-news .news-carousel::before {
	height: 80px;
}

#home .r-featured-news .news-carousel .inner p {
	line-height: 27px;
}

#home .title-center h3::after {
	content: '';
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -11px;
	width: 48px;
	height: 4px;
	background-color: #0063aa;
	z-index: 1;
}

/* -------------------------------------------------------------TABLET---------------------------------------- */

@media (max-width: 1023px) {
	#home .r-service .service-list .box {
		width: 48%;
	}

	#home .r-featured-news .news-carousel .inner svg {
		width: 50px;
	}

	#home .r-featured-news .news-carousel .inner p {
		font-size: 14px;
		line-height: 24px;
	}

	#home .r-featured-news .news-carousel {
		padding-left: 0;
	}

	#home .r-recruitment .image img {
		display: none;
	}

	#home .r-featured-news .news-carousel::before,
	#home .r-featured-news .news-carousel::after {
		opacity: 0;
	}

	#home .r-featured-news .news-carousel .owl-nav {
		transform: none;
		right: 0;
		top: -58px;
	}

	#home .r-featured-news h3.title {
		font-size: 30px;
		line-height: 47px;
		margin-bottom: 20px;
	}

	#home .r-technology .box h3 {
		font-size: 20px;
	}

	#home .r-technology .box ul li {
		flex: 0 0 calc(20%);
		max-width: 20%;
	}

	#home .r-technology .box {
		padding: 18px 12px 33px;
	}

	#home .r-step .step-carousel .item .top .text,
	#home .r-step .step-carousel .item .top .number {
		font-size: 30px;
	}

	#home .r-step {
		padding-bottom: 70px;
	}

	/* #home .r-process .col-right ul li:hover .icon {
		transform: scale(1.3);
	} */

	/* #home .r-process .col-right ul li:hover .content {
		padding-left: 32px;
	} */

	#home .r-news .list li .image {
		width: 360px;
		max-width: 100%;
		min-width: auto;
	}

	#home .r-featured-news a.btnNextNews svg {
		width: 50px;
	}

	#home .r-featured-news a.btnNextNews {
		right: 14px;
	}

	#home .r-featured-news .news-carousel .slick-prev {
		left: 0;
		right: -560px;
		top: -40px;
	}

	#home .r-featured-news .news-carousel .slick-next {
		left: 0;
		right: -666px;
		top: -40px;
	}

	#home .r-reason .box ul li .icon img {
		width: 80%;
	}

	#home .r-reason .box ul li .icon {
		margin-right: 10px;
	}

	#home .r-coporate .row .box .content .inner {
		max-height: 170px;
	}

	#home .r-coporate .row .box .content .inner p {
		line-height: 23px;
	}

	#home .r-coporate .row .box .content .inner p {
		font-size: 11px !important;
	}

	#home .r-coporate .row .box .content .inner .customFz {
		font-size: 25px;
		line-height: 40px;
	}

	#home .r-process .col-left h3 {
		font-size: 30px;
	}

	#home .r-process .col-left .subtract-2 {
		display: none;
	}

	#home .r-process .col-left .subtract {
		display: none;
	}

	#home .r-process .col-right ul li::before {
		width: 550px;
	}

	#home .r-process .col-right ul li::before {
		width: calc(100% - 20px);
		height: 90px;
	}

	/* #home .r-process .col-right ul li:hover .content p {
		padding-right: 52px;
		font-size: 87px;
		transition: 0.4s;
	} */

	/* #home .r-process .col-right ul li:hover .content p {
		font-size: 70px;
	} */

	#home .r-process .col-right ul li::after {
		height: 70px;
	}

	#home .r-technology .title-left .desc {
		font-size: 14px;
		line-height: 24px;
	}

	#home .r-technology .box::after {
		font-size: 88px;
	}

	#home .r-technology .box::after {
		font-size: 62px;
	}

	#home .r-featured-project .item .box {
		margin-right: 15px;
	}

	#home .r-featured-project .item .box img {
		width: 100%;
	}

	#home .r-archivement .title-center p {
		font-size: 14px;
	}

	#home .r-news .list li {
		gap: 33px;
	}

	#home .r-news .list li .type .date {
		font-size: 16px;
		margin-right: 0;
	}

	#home .r-news .list li .type .date {
		padding: 15px 0 21px;
	}

	#home .r-news .list li .type span {
		font-size: 16px;
		height: 36px;
		margin-top: 38px;
	}

	#home .r-news .list li .type span {
		margin-top: 0;
	}

	#home .r-office-tcom .col-left {
		flex: 0 0 27%;
		max-width: 27%;
	}

	#home .r-office-tcom .col-right {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.r-recruitment .col-left {
		flex: 0 0 29%;
		max-width: 29%;
	}

	.r-recruitment .col-right {
		flex: 0 0 71%;
		max-width: 71%;
		padding-left: 69px;
		margin-top: -14px;
	}

	.r-moment .col-left {
		flex: 0 0 53.5%;
		max-width: 53.5%;
	}

	.r-moment .col-right {
		flex: 0 0 46.5%;
		max-width: 46.5%;
	}

	#home .r-office-tcom .col-left .btn-start {
		padding: 10px 30px 10px 30px;
		font-size: 24px;
	}

	#home .r-office-tcom .col-left .inner {
		padding-top: 0;
		padding-left: 0;
		margin-bottom: 40px;
	}

	#home .r-office-tcom .col-left p {
		font-size: 14px;
	}

	#home .r-office-tcom .col-left h3 {
		font-size: 28px;
		line-height: 38px;
	}

	#home .r-office-tcom .col-left h3::before {
		bottom: -7px;
	}

	#home .r-office-tcom .circle {
		display: none;
	}

	#home .r-faq .title-center h3::after {
		width: 230px;
	}

	#home .r-faq .title-center h3 {
		font-size: 30px;
		line-height: 40px;
	}

	#home .r-news .list li .title {
		font-size: 14px;
		line-height: 21px;
		margin-top: -23px;
		margin-bottom: 18px;
	}

	#home .r-news .list li .type {
		font-size: 14px;
		line-height: 24px;
		width: 120px;
		margin-right: 80px;
	}

	#home .r-news .list li .date {
		font-size: 14px;
		line-height: 24px;
		padding: 10px 20px;
		min-width: 145px;
		margin-right: 30px;
	}

	#home .r-news {
		margin-top: 80px;
		padding-bottom: 100px;
		background-size: cover;
	}

	#home .r-news .title-left .desc {
		font-size: 14px;
		line-height: 24px;
	}

	#home .r-news .title-left {
		margin-bottom: 40px;
	}

	#home .r-moment {
		margin-top: 80px;
	}

	#home .r-moment .inner .col-7 {
		margin-bottom: 6px;
	}

	#home .r-moment .title-center h3 {
		line-height: 40px;
	}

	#home .r-recruitment {
		padding: 50px 0;
	}

	#home .r-recruitment .job-list .box .content ul li {
		font-size: 14px;
	}

	#home .r-recruitment .job-list .box .content h3 {
		font-size: 20px;
		line-height: 30px;
	}

	#home .r-recruitment .job-list .box .title h2 {
		font-size: 28px;
	}

	#home .r-recruitment .title-left .desc {
		font-size: 14px;
	}

	#home .r-recruitment .title-left h3 {
		font-size: 30px;
		line-height: 40px;
	}

	#home .r-recruitment .image img {
		position: static;
		width: 100%;
		margin-bottom: 50px;
	}

	#home .r-archivement .box p {
		font-size: 14px;
		line-height: 24px;
	}

	#home .r-archivement .box h2 {
		font-size: 30px;
		line-height: 40px;
	}

	#home .r-archivement .row {
		margin-left: -10px;
		margin-right: -10px;
	}

	#home .r-archivement .row>div {
		padding-left: 10px;
		padding-right: 10px;
	}

	#home .r-featured-project .item .box .content p {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 5px;
		padding-right: 15px;
	}

	#home .r-featured-project .item .box .content h3 {
		font-size: 30px;
		line-height: 40px;
	}

	#home .r-featured-project .title-left {
		margin-bottom: 30px;
	}

	#home .r-featured-project {
		padding: 70px 0;
	}

	#home .r-step .title-center .desc {
		font-size: 14px;
	}

	#home .title-center h3 {
		font-size: 30px;
		line-height: 40px;
	}

	#home .r-coporate .row .box .content .inner p {
		font-size: 14px;
		margin-bottom: 0;
	}

	#home .r-coporate .row .box .content .inner .customFz+p {
		margin-bottom: 29px;
	}

	#home .r-coporate .row .box .content .inner h1 {
		font-size: 25px;
		line-height: 40px;
	}

	#home .r-coporate .row .box .content .icon img {
		width: 115px;
	}

	#home .r-coporate .top p {
		font-size: 14px;
	}

	#home .r-coporate .top h2 {
		font-size: 40px;
		line-height: 55px;
	}

	#home .r-reason .box ul li p {
		font-size: 30px;
		line-height: 44px;
	}

	#home .r-reason .box .group-reason {
		margin-right: 0;
		margin-bottom: 40px;
		text-align: center;
		display: none;
	}

	#home .r-reason .box {
		flex-direction: column;
	}

	#home .r-reason .title {
		font-size: 40px;
		line-height: 55px;
		margin-bottom: 50px;
	}

	#home .title-left h3 {
		font-size: 30px;
		line-height: 38px;
	}

	#home .title-left .desc {
		font-size: 14px;
	}

	#home .link svg {
		width: 50px;
	}

	#home .r-big-banner {
		margin-top: 0;
	}

	#home .r-big-banner .content h1 {
		font-size: 30px;
		line-height: 38px;
	}

	#home .r-big-banner .content p {
		font-size: 14px;
		line-height: 24px;
	}

	#home .r-big-banner .content .btn-readmore {
		padding: 9.5px 0;
		font-size: 14px;
		border-radius: 61px;
		width: 207px;
		position: relative;
		justify-content: center;
		max-width: 100%;
	}

	#home .r-big-banner .content .btn-readmore svg {
		width: 30px;
	}

	#home .r-big-banner {
		padding: 100px 0;
	}

	#home .r-big-banner .content {
		width: 455px;
		max-width: 100%;
		text-align: left;
		margin-top: 0px;
	}

	#home .title-left .desc {
		font-size: 14px;
		line-height: 24px;
	}

	#home .r-archivement .title-center p {
		font-size: 14px;
		line-height: 24px;
	}

	#home .r-reason .box .group-reason {
		margin-right: 0px;
	}

	#home .r-office-tcom .col-left {
		flex: 0 0 100%;
		display: flex;
		max-width: 100%;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		align-content: center;
	}



	#home .r-big-banner {
		padding: 18% 0% !important;
	}
}

/* --------------------------------------------------MOBILE------------------------------------------------------- */
@media (max-width: 767px) {


	#home .r-recruitment .job-list .box .check::after {
		background: #0063aa;
		margin-top: 0px;
		content: '';
		height: 132px;
		left: 40px;
		position: absolute;
		width: 7px;
	}

	#home .featured-project-carousel {
		padding-left: 15px;
	}

	#home .r-featured-project .customSlickSlider .slick-list {
		margin-left: 3px;
		margin-right: 65px;
	}

	#home .r-featured-project .slick-slide {
		padding: 0px;
		margin: 0 -1px;
	}

	#home .r-technology .box::after {
		font-size: 63px;
	}

	#home .r-reason .box ul li+li {
		margin-top: 36px;
	}

	#home .r-coporate {
		margin-top: 38px;
	}

	#home .r-coporate .row .box .content .inner .customFz {
		font-size: 22px;
		line-height: 30px;
	}

	#home .r-process {
		margin-top: 50px;
	}

	#home .r-process .col-left {
		padding-top: 20px;
	}

	#home .r-process .col-left h3 {
		font-size: 18px;
	}

	#home .r-process .col-left h3::after {
		width: 35px;
		bottom: 2px;
	}

	#home .r-process .col-right {
		padding-left: 15px;
	}

	#home .r-process .col-right ul::before {
		left: 48px;
	}

	#home .r-process .col-right ul li::before {
		width: calc(100% - 10px);
	}

	#home .r-process .col-right ul li:hover .content .text strong {
		font-size: 16px;
	}

	#home .r-process .col-right ul li .content {
		padding-left: 17px;
	}

	#home .r-process .col-right ul li .content p {
		font-size: 35px;
	}

	#home .r-process .col-right ul li .content p::before {
		height: 60px;
		top: calc(50%);
	}

	#home .r-process .col-right ul li .content .text strong {
		font-size: 16px;
		line-height: 42px;
	}

	#home .r-process .col-right ul li .content .text span {
		font-size: 11px;
	}

	#home .r-technology {
		margin-top: 30px;
	}

	#home .r-technology .box::after {
		font-size: 99px;
	}

	#home .r-featured-project {
		padding: 54px 0;
	}

	#home .r-news .btnMoreNews {
		display: block;
	}

	#home .r-news .list li {
		gap: 0;
	}

	#home .r-news .list li .image {
		width: 100%;
		border-radius: 5px;
	}

	#home .r-news .list li .type {
		font-size: 18px;
	}

	#home .r-news .list li .type .date {
		font-size: 18px;
		margin-right: 10px;
	}

	#home .r-service .service-list .box {
		width: 100%;
	}

	#home .r-featured-news .news-carousel .slick-next {
		left: 0;
		right: -325px;
		top: -40px;
	}

	#home .r-featured-news .news-carousel .slick-next svg {
		width: 22px;
	}

	#home .r-process .col-right ul li:hover .content .text span {
		transition: 0.3s;
		bottom: -5px;
		font-size: 16px;
		line-height: 19px;
	}

	/* #home .r-process .col-right ul li:hover .content {
		padding-left: 50px;
	} */

	#home .r-news .list li+li::after {
		content: '';
		top: -27px;
		height: 1px;
		background: #d0d0d0;
		position: absolute;
		width: 606px;
		left: 50%;
		transform: translateX(-50%);
	}

	/* #home .r-process .col-right ul li:hover::before {
		opacity: 1;
	} */

	/* #home .r-process .col-right ul li:hover .icon {
		transform: scale(1.9);
		margin-top: 0px;
	} */

	#home .r-news .content {
		width: 100%;
	}

	#home .r-news .list li .type span {
		margin-top: 10px;
		font-size: 14px;
		line-height: 33px;
		padding: 0 15px;
		height: 26px;
		position: absolute;
		right: 5px;
	}

	#home .r-featured-news .news-carousel .inner p {
		width: 100%;
	}

	#home .r-big-banner {
		background: none;
	}

	#home .r-big-banner-mb {
		display: block !important;
		margin-top: -95px;
	}

	#home .r-big-banner-mb img {
		width: 100%;
		object-fit: cover;
	}

	#home .r-big-banner .content {
		width: 100%;
		text-align: center;
	}

	#home .r-featured-news {
		margin-top: -115px;
		position: relative;
	}

	#home .r-featured-news .news-carousel .slick-prev {
		left: 0;
		right: -230px;
		top: -40px;
	}

	#home .r-featured-news .news-carousel .slick-prev svg {
		width: 22px;
	}

	#home .r-featured-news a.btnNextNews {
		display: block;
		top: -36px;
	}

	#home .main-content {
		overflow-x: hidden;
	}

	#home .owl-nav button>svg {
		width: 18px;
	}

	#home .r-recruitment .job-list .box .check::after {
		background: #0063aa;
		margin-top: 0px;
		content: '';
		height: 110px;
		left: 40px;
		position: absolute;
		width: 7px;
	}

	#home .r-featured-project .featured-project-carousel:before {
		background-color: #0063aa;
		content: '';
		height: 101%;
		position: absolute;
		right: 99%;
		width: 10000%;
		z-index: 10;
	}

	#home .r-big-banner .content {
		width: 455px;
		max-width: 100%;
		text-align: center;
	}

	#home .r-big-banner-mb {
		display: block !important;
		margin-top: -50px;
	}

	#home .r-news .title-left {
		text-align: center;
	}

	#home .r-office-tcom .col-left .inner {
		text-align: center;
		margin-bottom: 70px;
	}

	#home .r-office-tcom {
		margin-top: 50px;
		margin-bottom: 100px;
	}

	/* #home .r-coporate .row .box .content .icon img{
width: 64px;
} */
	/* #home .r-coporate .row .box .content .icon {
margin-top: 75px;
} */
	#home .r-office-tcom .col-left .btn-start svg {
		width: 20px;
	}

	#home .r-office-tcom .col-right .tcom-image .slick-dots {
		margin-top: 20px;
	}

	#home .r-office-tcom .col-left h3 {
		font-size: 27px;
		line-height: 28px;
		margin-bottom: 59px;
	}

	#home .r-office-tcom .col-left h3::before {
		width: 123px;
		height: 5px;
		bottom: -22px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 43px;
	}

	#home .r-office-tcom .col-left .btn-start {
		padding: 0px 20px 0px 20px;
		font-size: 14px;
		position: relative;
	}

	#home .r-office-tcom .col-left .btn-start::after {
		content: '';
		position: absolute;
		bottom: -47px;
		background: #fde002;
		width: 44px;
		height: 44px;
		border-radius: 50%;
		left: -79px;
	}

	#home .r-office-tcom .col-left .btn-start::before {
		content: '';
		position: absolute;
		background: #eb2725;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		top: -20px;
		right: -32px;
	}

	#home .r-office-tcom .col-right .tcom-image {
		margin-right: 15px;
	}

	#home .r-faq .title-center {
		margin-bottom: 60px;
	}

	#home .r-faq .card .card-body {
		font-size: 14px;
		line-height: 24px;
	}

	#home .r-faq .card .card-body {
		padding-right: 0;
	}

	#home .r-news .list li .date {
		padding: 5px 20px;
		min-width: 130px;
	}

	#home .r-news .list li .type {
		width: auto;
		margin: 10px 0 10px;
		width: 100%;
	}

	#home .r-news .list li .type::after {
		display: none;
	}

	#home .r-news .list li {
		flex-direction: column;
		align-items: flex-start;
	}

	#home .r-moment .title-center {
		margin-bottom: 40px;
	}

	#home .r-moment {
		margin-top: 50px;
	}

	#home .r-recruitment .title-left {
		margin-bottom: 30px;
	}

	#home .r-recruitment .job-list .box .content ul li .name {
		min-width: 70px;
		margin-right: 15px;
	}

	#home .r-recruitment .job-list .box .content ul li+li {
		margin-top: 0px;
	}

	#home .r-recruitment .job-list .box .content h3 {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 0;
	}

	#home .r-recruitment .job-list .box .title {
		min-width: 70px;
		margin-right: 15px;
	}

	#home .r-recruitment .job-list .box .title h2 {
		font-size: 18px;
	}

	#home .r-recruitment .job-list .box .check {
		padding: 40px 14px;
		margin-right: 15px;
	}

	#home .r-recruitment .job-list .box .check svg {
		width: 15px;
	}

	#home .r-recruitment .title-left h3 {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 10px;
	}

	#home .r-archivement .box .icon {
		height: 50px;
		margin-bottom: 10px;
	}

	#home .r-archivement .box .icon img {
		width: 50px;
	}

	#home .r-archivement .box h2 {
		font-size: 18px;
		line-height: 28px;
	}

	#home .r-archivement .row>div {
		margin-bottom: 20px;
	}

	#home .r-archivement .title-center {
		margin-bottom: 30px;
	}

	#home .r-archivement .title-center h3::after {
		bottom: -10px;
	}

	#home .r-archivement {
		padding: 50px 0;
	}

	#home .r-featured-project .item .box .content {
		padding: 20px 0 0 15px;
	}

	#home .r-featured-project .item .box .content h3 {
		font-size: 18px;
		line-height: 28px;
	}

	#home .r-featured-project {
		margin-top: 20px;
	}

	#home .link svg {
		display: none;
	}

	#home .title-center h3::after {
		bottom: -10px;
		width: 23px;
		height: 1px;
	}

	#home .title-center h3 {
		font-size: 18px;
		line-height: 28px;
	}

	#home .r-coporate .row .box .content .inner {
		padding: 20px;
		max-height: 150px;
	}

	#home .r-coporate .row .box .content .inner h1 {
		font-size: 20px;
		line-height: 30px;
	}

	#home .r-coporate .row .box .content {
		padding: 20px;
	}

	#home .r-coporate .row>div {
		margin-bottom: 15px;
	}

	#home .r-coporate .top {
		margin-bottom: 40px;
	}

	#home .r-coporate .top .link {
		display: none;
	}

	#home .r-coporate .top p br {
		display: none;
	}

	#home .r-coporate .top h2 {
		font-size: 18px;
		line-height: 28px;
		position: relative;
	}

	#home .r-coporate .top h2::before {
		content: '';
		position: absolute;
		width: 24px;
		height: 2px;
		background: #0063aa;
		bottom: 0;
		left: 50%;
		transform: translateY(-50%);
	}

	#home .r-reason .box ul li p {
		font-size: 14px;
		line-height: 24px;
	}

	#home .r-reason {
		padding: 50px 0;
	}

	#home .r-reason .title {
		font-size: 20px;
		line-height: 28px;
		margin-bottom: 30px;
	}

	#home .r-service .service-list .box h3 {
		font-size: 16px;
	}

	#home .r-service .service-list .box .icon img {
		width: 30px;
	}

	#home .r-service {
		padding: 50px 0;
	}

	#home .r-service .top .link {
		display: none;
	}

	#home .title-left .desc br {
		display: none;
	}

	#home .title-left h3::after {
		bottom: -10px;
		width: 123px;
		height: 3px;
		left: 2px;
	}

	#home .r-news .title-left h3::after {
		left: 50%;
		transform: translateX(-50%);
	}

	#home .title-left h3 {
		font-size: 24px;
		line-height: 28px;
	}

	#home .r-featured-news .news-carousel .inner svg {
		width: 30px;
	}

	#home .r-featured-news .news-carousel .owl-nav {
		top: -50px;
	}

	#home .r-featured-news .news-carousel .owl-nav svg {
		width: 22px;
	}

	#home .r-featured-news h3.title {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 20px;
	}

	#home .r-big-banner .content .btn-readmore svg {
		margin-left: 12px;
		display: none;
	}

	#home .r-big-banner .content .btn-readmore svg.icon-mb {
		display: block;
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
	}

	#home .r-big-banner .content p {
		margin-bottom: 30px;
	}

	#home .r-big-banner .content h1 {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 10px;
		padding: 0 15px;
	}

	#home .r-big-banner {
		padding: 32px 0;
		/* margin-top: 31px; */
	}

	#home .r-technology {
		padding: 30px 0;
	}

	#home .r-step .step-carousel {
		padding-right: 0;
	}

	#home .r-step {
		padding: 50px 0 227px;
	}

	#home .r-step .title-center {
		margin-bottom: 40px;
	}

	#home .r-step .title-center .desc {
		font-size: 14px;
	}

	#home .r-step .step-carousel .item h3 {
		font-size: 18px;
		line-height: 28px;
	}

	#home .r-step {
		padding-bottom: 40px;
	}

	#home .r-step .step-carousel .item .top {
		margin-bottom: 15px;
	}

	#home .r-step .step-carousel .item .top .text,
	#home .r-step .step-carousel .item .top .number {
		font-size: 18px;
	}
}

/* ------------------------SCREEN----------------------- */



@media screen and (min-width: 200px) and (max-width: 1620px) {
	#home .r-reason .label {
		right: calc(100% + 14px);
	}
}


@media screen and (min-width: 200px) and (max-width: 1440px) {
	#home .r-office-tcom .col-right .tcom-image .slick-active {
		margin-right: -35px;
	}
}




@media (max-width: 1440px) {
	#home .r-reason .label {
		display: none;
	}

	#home .r-office-tcom .circle {
		display: none;
	}

	#home .r-office-tcom .col-right .tcom-image .item.slick-active {
		margin-right: -45px;
	}
}

@media (min-width:1024px) {
	#home .r-process .col-right ul li:hover .content p {
		padding-right: 52px;
		font-size: 87px;
		transition: 0.4s;
	}

	#home .r-process .col-right ul li:hover .content .text strong {
		transition: 0.3s;
		font-size: 32px;
	}

	#home .r-process .col-right ul li:hover::before {
		opacity: 1;
	}

	#home .r-process .col-right ul li:hover::after {
		opacity: 1;
	}

	#home .r-process .col-right ul li:hover .icon {
		transform: scale(1.9);
		margin-top: 0px;
	}
}


@media (min-width:1721px) and (max-width: 1820px) {
	.img-content {
		top: 180px !important;
		left: 18% !important;
	}

	.img-content>h1 {
		font-size: 32px !important;

	}
}


@media (min-width:1679px) and (max-width: 1720px) {
	.img-content {
		top: 180px !important;
		left: 18.5% !important;
	}

	.img-content>h1 {
		font-size: 32px !important;

	}
}

@media (min-width:1500px) and (max-width: 1679px) {
	.img-content {
		top: 180px !important;
		left: 16% !important;
		width: 472.85px !important;
	}

	.img-content>h1 {
		font-size: 32px !important;
		width: 480px !important;

	}

	.img-content>p {
		width: 380px;

	}

	.img-content>a {
		padding: 25px 40px !important;
	}
}

@media (min-width:1400px) and (max-width: 1499px) {
	.img-content {
		top: 180px !important;
		left: 12% !important;
		width: 472.85px !important;
	}

	.img-content>h1 {
		font-size: 32px !important;
		max-width: 500px !important;
	}

}

@media (min-width:1250px) and (max-width: 1399px) {
	.img-content {
		top: 180px !important;
		left: 8.5% !important;
		width: 472.85px !important;
	}

	.img-content>h1 {
		font-size: 32px !important;
		max-width: 500px !important;
	}
}

@media(max-width: 1249px) {
	.r-big-slide-dt {
		display: none;
	}

	.r-big-banner-mbs {
		display: block !important;
	}

	.r-big-slide-mb {
		display: block !important;
	}
}


@media (min-width:500px) and (max-width: 600px) {
	.img-bg_mb {
		height: 820px !important;
	}

	.img-content_mb {
		top: 400px !important;
	}
}

@media (min-width:601px) and (max-width: 768px) {
	.img-bg_mb {
		height: 1100px !important;
		margin-bottom: 50px !important;
	}

	.img-content_mb {
		top: 550px !important;
	}

	.img-content_mb>h1 {
		font-size: 32px !important;
		line-height: 40px !important;
	}

	.img-content_mb {
		width: 500px !important;
		height: 432px !important;

		text-align: center;
	}
}


@media (min-width:768px) and (max-width: 900px) {
	.img-bg_mb {
		height: 1200px !important;
		margin-bottom: 50px !important;
		margin-top: 70px !important;
	}

	.img-content_mb {
		top: 550px !important;
	}

	.img-content_mb>h1 {
		font-size: 32px !important;
		line-height: 40px !important;
		width: 100% !important;
	}

	.img-content_mb {
		width: 500px !important;
		height: 432px !important;

		text-align: center;
	}

	.img-content_mb>p {
		font-size: 24px !important;
		line-height: 30px !important;
		max-width: 400px !important;
	}
}


@media (min-width:900px) and (max-width: 1000px) {
	.img-bg_mb {
		height: 1500px !important;
		margin-bottom: 50px !important;
		margin-top: 70px !important;
	}

	.img-content_mb {
		top: 850px !important;
	}

	.img-content_mb>h1 {
		font-size: 32px !important;
		line-height: 40px !important;
	}

	.img-content_mb {
		width: 500px !important;
		height: 432px !important;
		width: 100% !important;
		text-align: center;
	}

	.img-content_mb>p {
		font-size: 24px !important;
		line-height: 30px !important;
		max-width: 400px !important;
	}
}



@media (min-width:1001px) and (max-width: 1149px) {
	.img-bg_mb {
		height: 1715px !important;
		margin-bottom: 50px !important;
		margin-top: 70px !important;
	}

	.img-content_mb {
		top: 850px !important;
	}

	.img-content_mb>h1 {
		font-size: 32px !important;
		line-height: 40px !important;
		width: 100% !important;
	}

	.img-content_mb {
		width: 500px !important;
		height: 432px !important;

		text-align: center;
	}

	.img-content_mb>p {
		font-size: 24px !important;
		line-height: 30px !important;
		max-width: 400px !important;
	}
}


@media (min-width:1150px) and (max-width: 1249px) {
	.img-bg_mb {
		height: 1850px !important;
		margin-bottom: 50px !important;
		margin-top: 70px !important;
	}

	.img-content_mb {
		top: 850px !important;
	}

	.img-content_mb>h1 {
		font-size: 32px !important;
		line-height: 40px !important;
		width: 100% !important;
	}

	.img-content_mb {
		width: 500px !important;
		height: 432px !important;

		text-align: center;
	}

	.img-content_mb>p {
		font-size: 24px !important;
		line-height: 30px !important;
		max-width: 400px !important;
	}
}

@media (min-width:1024px) and (max-width: 1200px) {
	#home .r-coporate .row .box .content .icon {
		margin-top: calc(50% - 100px);
		transform: translateY(-50%);
	}
}



@media (min-width:400px) and (max-width: 450px) {
	.img-bg_mb {
		height: 680px !important;
	}
}



@media (min-width: 1250px) and (min-height:800px) and (max-height: 1079px) {
	.img-content {
		top: 24% !important;
		left: 12%;
	}
}


@media (min-width: 1250px) and (max-width: 1499px) and (min-height:800px) and (max-height: 935px) {
	.img-content {
		top: 24% !important;
		left: 12% !important;
	}
}

@media(min-width: 1250px) and (min-height:721px) and (max-height: 799px) {
	.img-content {
		top: 20% !important;
		left: 18% !important;
		width: 396px !important;

	}

	.pagi-slick {
		display: none !important;
	}

	.img-content>a {
		padding: 22px 33px !important;
	}
}



@media(min-width: 1450px) and (min-height:600px) and (max-height: 720px) {
	.img-content {
		top: 16% !important;
		left: 18% !important;

	}

	.pagi-slick {
		display: none !important;
	}
}

@media(min-width: 1250px) and (min-height:600px) and (max-height: 720px) {
	.img-content {
		top: 18% !important;
		left: 18% !important;

	}

	.pagi-slick {
		display: none !important;
	}
}

@media (min-width: 1250px) and (min-height:1080px) {
	.img-content {
		top: 235px !important;
	}
}

.ndbasdbadbqbabas {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
}


.nandnadabdb {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
}

.qeadsad {

	position: fixed;
	opacity: 0;
	transition: .3s;
}

.ndasndanq2 {
	margin-top: 54px;
}